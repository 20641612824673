import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import EndPoint from '../../api/Endpoint';
import { LIST_CRR_PAGE, STEPS_LIST_ROWS_DEF } from '../../constants/global';
import { axiosRequest } from '../common-api/axios';

const initialState = {
    clientCaseData: [],
    loading: false,
    particularClientObj: {},
    listObj: {
        currentPageProp: LIST_CRR_PAGE,
        rowsProp: STEPS_LIST_ROWS_DEF,
    },
};

// GET ALL CLIENT CASE LIST
export const getClientCasesListAction = createAsyncThunk(
    "composeEmailModule/getClientCasesListAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.CLIENT_DRAFT_EMAIL_LIST_API}?page=${value.currentPage}&size=${value.rows}&userPrincipalName=${value.currentUserEmail}&client_id=${value.clientID}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

// DELETE CLIENT CASE 
export const deleteClientCasesAction = createAsyncThunk(
    "composeEmailModule/deleteClientCasesAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.CLIENT_DRAFT_EMAIL_LIST_DELETE_API}?userPrincipalName=${value.currentUserEmail}&email_id=${value.draftEmailId}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//GETTING COMMON CASE TYPES FOR CLIENT CASES
export const getCommonCaseTypesForClientCasesAction = createAsyncThunk(
    "composeEmailModule/getCommonCaseTypesForClientCasesAction",
    async () => {
        const apiUrl = `${EndPoint.GET_ALL_CASE_TYPES}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//GETTING COMMON RELATIVES FOR CLIENT CASES
export const getCommonRelativesForClientCasesAction = createAsyncThunk(
    "composeEmailModule/getCommonClientForClientCasesAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.GET_ALL_RELATIVES}/${value.clientID}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//GETTING FILE NO FOR CLIENT CASES
export const getFileNoForClientCasesAction = createAsyncThunk(
    "composeEmailModule/getCommonClientForClientCasesAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.GET_FILE_NO_CLIENT_CASE}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//GETTING COMMON TEAMS FOR CLIENT CASES
export const getAllTeamsForClientCasesAction = createAsyncThunk(
    "composeEmailModule/getAllTeamsForClientCasesAction",
    async (value: any) => {
        const apiUrl = `${EndPoint.GET_COMMON_TEAMS}`
        try {
            const response = await axiosRequest.get(apiUrl);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//GETTING TEAMS FOR CLIENT CASES
export const getTeamsDropdownsDataAction = createAsyncThunk(
    "teamsModule/getTeamsDropdownsDataAction",
    async () => {
        try {
            const response = await axiosRequest.get(EndPoint.TEAMS_DROPDOWNS_DATA_API);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);


//ADD CLIENT CASES
export const addClientCaseDataAction = createAsyncThunk(
    "teamsModule/addClientCaseDataAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.post(EndPoint.ADD_CLIENT_CASES, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

//EDIT CLIENT CASES
export const editClientCaseDataAction = createAsyncThunk(
    "teamsModule/editClientCaseDataAction",
    async (value:any) => {
        try {
            const response = await axiosRequest.put(EndPoint.EDIT_CLIENT_CASES, value);
            return response;
        } catch (error: any) {
            return error;
        }
    }
);

const ClientCaseslice = createSlice({
    name: 'draft email',
    initialState: initialState,
    reducers: {
        changeListDataObj(state, actions) {
            state.listObj.currentPageProp = actions.payload.currentPage;
            state.listObj.rowsProp = actions.payload.rows;
        },
        startLoader(state, actions) {
            state.loading = true;
        },
        stopLoader(state, actions) {
            state.loading = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Listing of Draft Email
            .addCase(getClientCasesListAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getClientCasesListAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getClientCasesListAction.rejected, (state, action) => {
                state.loading = false;
            })

            // Delete Draft Email
            .addCase(deleteClientCasesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteClientCasesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteClientCasesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(addClientCaseDataAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(addClientCaseDataAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(addClientCaseDataAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCommonCaseTypesForClientCasesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCommonCaseTypesForClientCasesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getCommonCaseTypesForClientCasesAction.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getCommonRelativesForClientCasesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCommonRelativesForClientCasesAction.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(getCommonRelativesForClientCasesAction.rejected, (state, action) => {
                state.loading = false;
            })
    }
});


export const draftEmailModuleActions = ClientCaseslice.actions;

export default ClientCaseslice.reducer;